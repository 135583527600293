import React from "react";
// import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

export default function Footer() {

  // let location = useLocation()

  return (
    <section className="footer-text" style={{ width: '100%', backgroundColor: 'transparent', color: 'var(--text-secondary)', height: 100, padding: 40, textAlign: 'center', marginTop: 20 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Col style={{ textAlign: 'center' }}>
          <p className="muted small">hello@florolabs.com</p>
          <p className="tiny">Copyright 2024, Floro Health Inc.</p>
        </Col>
      </Row>
    </section>
  )

}