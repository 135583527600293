import React, { useContext, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from './shared-functions.js';
import { Row, Col, Navbar, Nav, Image } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
// import { ArrowRight, Strategy, Notepad, ChartLineUp } from "@phosphor-icons/react";
import Logo from "./components/common/Logo.js";
import { ThemeContext } from "./Theme.js";

export default function Home() {

  const navigate = useNavigate();
  const location = useLocation();
  let isPageWide = useMediaQuery('(min-width: 640px)');
  const { theme } = useContext(ThemeContext);

  const [validated, setValidated] = useState(false);
  const [openIntakeForm, setOpenIntakeForm] = useState(false);

  const scrollEffect = ( targetRef ) =>{
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  return (
    <Row style={{ justifyContent: 'center', minHeight: '90vh', width: '100%', marginLeft: 0, marginRight: 0 }}>
      <Col style={{ padding: 0 }}>
        <Navbar justify="true" fixed="top" variant={ theme === 'light-theme' ? "light" : "dark" } style={{ padding: '15px 20px', backgroundColor: `var(--bg-primary)` }}>
          <Logo width={140} />
        </Navbar>

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20, paddingBottom: 40 }}>
          <Col xs={11} sm={11} md={6} lg={6} xl={5} style={{ padding: 10, maxWidth: 800, textAlign: 'center' }}>
            <h1 style={{ maxWidth: 600 }}>At-home gut microbiome testing</h1>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <Col xs={11} sm={10} md={8} lg={8} xl={8} style={{ padding: 0 }}>
                <p className='large' style={{ marginTop: 10 }}>Understand your gut on 29 biomarkers with an easy-to-use metagenomic gut sequencing test. Order your kit today and get your results in 2 weeks.</p>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={11} sm={11} md={6} lg={5} xl={5} style={{ padding: 20, maxWidth: 800 }}>

          </Col> */}
        </Row>

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 10, marginRight: 10, marginTop: 0, paddingBottom: 40 }}>
          <Col xs={11} sm={11} md={6} lg={6} xl={10}>
            <Image src={'/assets/frontpage/banner.png'} style={{ width: '100%', objectFit: 'cover' }} />
          </Col>
        </Row>

      </Col>
      <Footer />
    </Row>
  )


}
